// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-apply-credit-card-js": () => import("./../../../src/pages/apply-credit-card.js" /* webpackChunkName: "component---src-pages-apply-credit-card-js" */),
  "component---src-pages-banking-js": () => import("./../../../src/pages/banking.js" /* webpackChunkName: "component---src-pages-banking-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-insurance-online-js": () => import("./../../../src/pages/insurance-online.js" /* webpackChunkName: "component---src-pages-insurance-online-js" */),
  "component---src-pages-khata-js": () => import("./../../../src/pages/khata.js" /* webpackChunkName: "component---src-pages-khata-js" */),
  "component---src-pages-lending-partners-js": () => import("./../../../src/pages/lending-partners.js" /* webpackChunkName: "component---src-pages-lending-partners-js" */),
  "component---src-pages-loans-js": () => import("./../../../src/pages/loans.js" /* webpackChunkName: "component---src-pages-loans-js" */),
  "component---src-pages-media-center-index-js": () => import("./../../../src/pages/media-center/index.js" /* webpackChunkName: "component---src-pages-media-center-index-js" */),
  "component---src-pages-media-center-media-kits-js": () => import("./../../../src/pages/media-center/media-kits.js" /* webpackChunkName: "component---src-pages-media-center-media-kits-js" */),
  "component---src-pages-media-center-news-js": () => import("./../../../src/pages/media-center/news.js" /* webpackChunkName: "component---src-pages-media-center-news-js" */),
  "component---src-pages-media-center-press-release-js": () => import("./../../../src/pages/media-center/press-release.js" /* webpackChunkName: "component---src-pages-media-center-press-release-js" */),
  "component---src-pages-metro-stc-terms-and-conditions-js": () => import("./../../../src/pages/metro-stc/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-metro-stc-terms-and-conditions-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rewards-js": () => import("./../../../src/pages/rewards.js" /* webpackChunkName: "component---src-pages-rewards-js" */),
  "component---src-pages-terms-ca-js": () => import("./../../../src/pages/terms-ca.js" /* webpackChunkName: "component---src-pages-terms-ca-js" */),
  "component---src-pages-terms-casa-nsdl-ca-js": () => import("./../../../src/pages/terms-casa-nsdl-ca.js" /* webpackChunkName: "component---src-pages-terms-casa-nsdl-ca-js" */),
  "component---src-pages-terms-cfd-js": () => import("./../../../src/pages/terms-cfd.js" /* webpackChunkName: "component---src-pages-terms-cfd-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-terms-na-js": () => import("./../../../src/pages/terms-na.js" /* webpackChunkName: "component---src-pages-terms-na-js" */),
  "component---src-pages-terms-pi-js": () => import("./../../../src/pages/terms-pi.js" /* webpackChunkName: "component---src-pages-terms-pi-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-meta-js": () => import("./../../../src/templates/meta.js" /* webpackChunkName: "component---src-templates-meta-js" */),
  "component---src-templates-opinion-js": () => import("./../../../src/templates/opinion.js" /* webpackChunkName: "component---src-templates-opinion-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

