module.exports = [{
      plugin: require('../plugins/source-plugin-api/gatsby-browser.js'),
      options: {"plugins":[],"baseApiUrl":"https://www.chqbook.com/api/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chqbook.com","short_name":"Chqbook.com","start_url":"/","background_color":"#ffffff","icon":"static/favicon.png","description":"India's first Neo-Bank for Small Business Owners. Open a Current Account. Manage your Khata Online. Apply Online for Personal Loan, Business Loan, Credit Cards and Insurance.","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f2b5eb5682a7bae19450ce779fa7f553"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P4TL9P","includeInDevelopment":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
